import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import Layout from "../components/layout"

const Contact = ({ data: { contact } }) => (
  <Layout>
    <article className="sheet">
      <HelmetDatoCms seo="" />
      <div className="sheet__inner">
        <h1 className="sheet__title">{contact.title}</h1>
        <p className="sheet__lead">{contact.subtitle}</p>
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: contact.contactbodyNode.childMarkdownRemark.html,
          }}
        />
      </div>
    </article>
  </Layout>
)

export default Contact

export const query = graphql`
  query ContactQuery {
    contact: datoCmsContactpage {
      title
      subtitle
      contactbodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
